<template>
	<div class>
		<div
			class="tw-flex xl:tw-justify-between tw-space-s-5 tw-mt-7 tw-mb-9 md:tw-mt-12 md:tw-mb-12"
		>
			<div
				@click="
					;(user.type = 'email'),
						(user.phone = ''),
						$refs.forgotPassForm.reset()
				"
				class="md:tw-w-197px tw-w-129px tw-h-24 md:tw-h-125px tw-grid tw-place-content-center tw-rounded-xl tw-cursor-pointer"
				:class="
					user.type == 'email'
						? 'tw-bg-white tw-text-56'
						: 'tw-bg-84 tw-text-white'
				"
			>
				<img
					class="tw-mx-auto tw-mb-3"
					src="@/assets/images/email.png"
					alt
					srcset
				/>
				<span class="tw-text-xs">{{
					$t('forgotPassword.emailRecovery')
				}}</span>
			</div>
			<div
				@click="
					;(user.type = 'phone'),
						(user.email = ''),
						$refs.forgotPassForm.reset()
				"
				class="md:tw-w-197px tw-w-129px tw-h-24 md:tw-h-125px tw-grid tw-place-content-center tw-cursor-pointer tw-rounded-xl"
				:class="
					user.type != 'email'
						? 'tw-bg-white tw-text-56'
						: 'tw-bg-84 tw-text-white'
				"
			>
				<img
					class="tw-mx-auto tw-mb-3"
					src="@/assets/images/sms.png"
					alt
					srcset
				/>
				<span class="tw-text-xs">
					{{ $t('forgotPassword.mobileRecovery') }}</span
				>
			</div>
		</div>
		<v-form
			ref="forgotPassForm"
			v-model="isValid"
			:disabled="loading"
			@submit.prevent="handleForgotPass"
		>
			<div
				v-if="user.type == 'email'"
				for
				class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-white"
			>
				<icon-email></icon-email>
				<div class="tw-mx-4">
					<span class="tw-text-xs">
						{{ $t('forgotPassword.emailAddress') }}</span
					>
				</div>
			</div>
			<v-text-field
				solo
				:rules="[rules.required($t('email')), rules.email]"
				v-model="user.email"
				class="focus-bg-none"
				:placeholder="$t('forgotPassword.emailAddress')"
				v-if="user.type == 'email'"
				hide-details="auto"
			/>
			<div
				v-if="user.type == 'phone'"
				class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-white"
			>
				<v-icon dark>mdi-phone</v-icon>
				<div class="tw-mx-4">
					{{ $t('forgotPassword.PhoneNumber') }}
				</div>
			</div>
			<v-text-field
				solo
				:rules="[rules.required($t('forgotPassword.PhoneNumber'))]"
				v-model="user.phone"
				class="focus-bg-none"
				type="tel"
				v-if="user.type == 'phone'"
				:placeholder="$t('forgotPassword.PhoneNumber')"
				hide-details="auto"
			/>

			<div class="text-center lg:tw-mt-36 tw-mt-5">
				<v-btn
					dark
					block
					height="60"
					type="submit"
					color="#A53E50"
					:loading="loading"
					class="shadow tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
				>
					<span
						class="tw-text-18 tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
						>{{ $t('forgotPassword.resetPassword') }}</span
					>
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
import { createFormMixin } from '@/mixins/form-mixin'
export default {
	name: 'forgotPassForm',
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	props: {
		loading: Boolean,
	},
	data() {
		return {
			isValid: false,
			user: {
				email: '',
				type: 'email',
				phone: '',
			},

			selectedOption: 'email',
			remember: false,
			password: {
				show: false,
				confirmed: false,
			},
		}
	},
	methods: {
		handleForgotPass() {
			if (!this.$refs.forgotPassForm.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.$emit('forgotPass', this.user)
		},
	},
}
</script>

<style>
.v-input__append-inner .v-icon {
	color: #a53e50 !important;
}
.social-button {
	background-color: rgba(255, 255, 255, 0.17) !important;
}
</style>
