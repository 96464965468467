<template>
	<v-app class="login no-auth">
		<div
			class="tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto tw-flex tw-flex-col"
		>
			<v-row class="tw-flex-1">
				<v-col
					class="lg:tw-grid tw-place-content-center tw-px-4 md:tw-px-0 tw-hidden"
				>
					<div class="tw-text-center tw-max-w-md">
						<img
							class="tw-h-52 tw-object-contain tw-mx-auto"
							src="@/assets/images/logo.png"
							alt
							srcset
						/>
						<h3
							class="tw-font-semibold tw-text-2xl md:tw-text-3xl 3xl:tw-text-4xl tw-mt-6 3xl:tw-mt-14"
						>
							{{ $t('login.welcomeToMachla') }}
						</h3>
						<p class="3xl:tw-text-xl tw-mt-3 3xl:tw-mt-5 tw-text-70">
							{{ $t('login.buyingAndSellingMakeEasy') }}
						</p>
						<div class="tw-mt-28">
							<v-btn
								dark
								block
								depressed
								to="/login"
								color="#A53E50"
								class="tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
								:height="$vuetify.breakpoint.mdAndUp ? 60 : null"
							>
								<v-icon>mdi-exit-to-app</v-icon>
								<span
									class="tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
									>{{ $t('login.login') }}</span
								>
							</v-btn>

							<BtnSignUp />
						</div>
					</div>
				</v-col>
				<v-col class="tw-grid tw-place-content-center tw-bg-72 tw-relative">
					<!-- Back Button -->
					<v-btn
						icon
						dark
						width="48"
						height="48"
						class="tw-bg-white tw-absolute tw-top-8 tw-left-8 xl:tw-top-24 xl:tw-left-24"
						@click="onClickBack"
					>
						<v-icon color="primary">mdi-chevron-left</v-icon>
					</v-btn>

					<div class="tw-w-full sm:tw-w-96 md:tw-w-420 tw-px-5 md:tw-px-0">
						<div>
							<h2 class="tw-font-semibold tw-text-27px tw-text-FF">
								<!-- Forgot Password -->
								{{ $t('forgotPassword.forgotPassword') }}
							</h2>
							<p class="tw-text-17px tw-text-FF tw-mt-2 tw-opacity-70">
								{{ $t('forgotPassword.forgotPasswordHint') }}
							</p>
						</div>
						<!-- <div class="tw-flex tw-justify-between tw-mt-7 tw-mb-9 md:tw-mt-12 md:tw-mb-12">
						<div
							@click="selectedOption='email'"
							class="md:tw-w-197px tw-w-129px tw-h-24 md:tw-h-125px tw-grid tw-place-content-center tw-rounded-xl tw-cursor-pointer"
							:class="selectedOption=='email' ? 'tw-bg-white tw-text-56' : 'tw-bg-84 tw-text-white'"
						>
							<img class="tw-mx-auto tw-mb-3" src="@/assets/images/email.png" alt srcset />
							<span class="tw-text-xs">Email Recovery</span>
						</div>
						<div
							value="mobile"
							@click="selectedOption='mobile'"
							class="md:tw-w-197px tw-w-129px tw-h-24 md:tw-h-125px tw-grid tw-place-content-center tw-cursor-pointer tw-rounded-xl"
							:class="selectedOption!='email' ? 'tw-bg-white tw-text-56' : 'tw-bg-84 tw-text-white'"
						>
							<img class="tw-mx-auto tw-mb-3" src="@/assets/images/sms.png" alt srcset />
							<span class="tw-text-xs">Mobile Recovery</span>
						</div>
					</div>-->
						<v-alert
							dense
							outlined
							dismissible
							v-model="error.dialog"
							:type="error.type"
						>
							<span class="tw-text-white">{{
								error.message ||
									'Error while sending email, please try again!'
							}}</span>
						</v-alert>

						<forgotPassword
							:loading="loading"
							@forgotPass="handleForgotPass"
						/>
					</div>
				</v-col>
			</v-row>
		</div>
		<!-- <div class="overflow-visible login__card ">
      <v-card dark :loading="loading" :disabled="loading" class="glass blur-1">
        <div class="px-3 py-12 px-md-8">
          <v-card-title class="title login__title flex-column"
            >Welcome back!</v-card-title
          >
          <v-card-subtitle class="text-center body-2"
            >Login with your Email and password</v-card-subtitle
          >
          <v-card-text class="mt-5">
            <v-alert
              dense
              outlined
              dismissible
              v-model="error.dialog"
              type="error"
            >
              {{ error.message }}
            </v-alert>
          </v-card-text>
        </div>
      </v-card>
		</div>-->
	</v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { encodedCookies, hasHistory, omitEmpties } from 'vuelpers'

import forgotPassword from '@/components/forms/forgotPassword'
import BtnSignUp from '../components/BtnSignUp.vue'

export default {
	name: 'forgotPasswords',
	components: {
		forgotPassword,
		BtnSignUp,
	},
	data() {
		return {
			loading: false,
			error: {
				dialog: false,
				message: '',
				type: 'error',
			},

			selectedOption: 'email',
		}
	},
	methods: {
		...mapActions('auth', ['forgotPassword']),
		...mapActions('auth', ['verifyPhone', 'verifyEmail']),
		onClickBack() {
			return hasHistory() ? this.$router.back() : this.$router.push('/')
		},
		async handleForgotPass(data) {
			console.log(data)
			this.loading = true
			this.error.dialog = false

			const [err] = await this.forgotPassword(
				omitEmpties({
					email: data.email,
					type: data.type == 'email' ? 'email' : 'phone',
					phone: data.type == 'phone' ? `+88${data.phone}` : '',
				})
			)
			// console.log(res,'handleForgotPass');
			if (err) {
				console.log(err, 'handleForgotPass')
				this.loading = false
				this.error.dialog = true
				this.error.type = 'error'
				this.error.message =
					err.errors.email && err.errors.email.length
						? err.errors.email[0]
						: err.errors.phone && err.errors.phone.length
						? err.errors.phone[0]
						: 'An unexpected error occured!'
			} else {
				this.loading = false
				// this.error.dialog = true
				// this.error.type = 'success'
				// this.error.message = res.message
				encodedCookies.set([
					{ key: 'verifyType', value: data.type },
					{
						key: 'verify',
						value: data.type == 'email' ? data.email : `+88${data.phone}`,
					},
				])
				this.$router.push('/reset-password')
			}

			// this.loading = false;
			// this.error.dialog = true
			// this.error.type = 'success'

			// this.error.message = res.err
			// this.$toast.success("Successfully logged in.");
			// let { redirect } = { ...this.$route.query };
			// this.$router.replace(redirect ? redirect : "/");
		},
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.login {
	&__card {
		width: 450px;
		max-width: 90%;
		@include center;
	}
}
</style>
